import { useQuery } from 'react-query';

import { sdk } from '@services/FlexConnectAPI';

import Load from '@components/atoms/Load';
import NftCollection from '@components/molecules/NftCollection';
import ModalNavBar from '@/components/molecules/ModalScreen/NavBar.jsx';
import AuthLayout from '@layout/Auth';

function Profile({ userProfile }) {
  const { isLoading, error, data, isFetching } = useQuery('nfts', () =>
    sdk.api.getProfileNFTs({ 'pagination[pageSize]': 200 })
  );

  return (
    <AuthLayout navbar={<ModalNavBar userProfile={userProfile} />}>
      <Load loading={isLoading} height="400px">
        <NftCollection data={data} />
      </Load>
    </AuthLayout>
  );
}

export default Profile;
