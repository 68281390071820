import { useEthers, useTokenBalance } from '@usedapp/core';
import { formatEther } from '@ethersproject/units';

const coins = [
  {
    name: 'Wrapped Ethereum',
    address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
  },
  {
    name: 'Flex Ethereum',
    address: '0x18e1A50ea5626D9984ad014de3C3989C7625aD6f',
  },
  {
    name: 'STKS',
    address: '0xb625bD4B866DA272f8D3d0827E101dFcDd198667',
  },
];

// https://github.com/TrueFiEng/useDApp/blob/master/packages/example/src/components/TokenList/TokenList.tsx

export const Token = ({ name, contract, account }) => {
  const balance = useTokenBalance(contract, account);
  console.log('balance', balance);
  return (
    <div className="balance">
      {name}
      {balance && <p className="bold">{formatEther(balance)}</p>}
    </div>
  );
};

export const TokenBalanceList = ({ account }) => {
  return (
    <div>
      {coins.map((i, index) => (
        <Token
          key={index}
          contract={i.address}
          name={i.name}
          account={account}
        />
      ))}
    </div>
  );
};

export default TokenBalanceList;
