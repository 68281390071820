import React, { useEffect } from 'react';
import { Box, Text, Image, Stack } from '@chakra-ui/react';

const NFTPreview = ({ images = [], totalCount = 0 }) => {
  return (
    <Box mr="-px" display={'flex'} alignItems="center">
      {images.map(url => {
        if (!url) return null;
        return (
          <Image borderRadius="full" boxSize="40px" ml="-10px" src={url} />
        );
      })}

      {totalCount - 3 > 0 && (
        <Box
          bg="#1B21B9"
          color="white"
          boxShadow="0px 4px 25px rgba(0, 0, 0, 0.13)"
          borderRadius="full"
          boxSize="40px"
          display="flex"
          ml="-10px"
          justifyContent="center"
          alignItems={'center'}
        >
          <Text fontSize={'18px'}>+{totalCount - 3}</Text>
        </Box>
      )}
    </Box>
  );
};

export default NFTPreview;
