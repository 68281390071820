import { Flex, Box, Stack, Button, Link, Text } from '@chakra-ui/react';

import { Link as ReachLink } from '@reach/router';

import emailVerifyIcon from '@/assets/icons/EmailVerify.svg';
import Icon from '@components/atoms/Icon';

export default function Complete({ userProfile }) {
  return (
    <Box flex="1">
      <Stack spacing={'20px'} mt="40px">
        <Box
          alignContent={'center'}
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <Icon iconUrl={emailVerifyIcon} width="180px" height="180px" />
        </Box>
        <Text
          fontSize="30px"
          fontWeight={'700'}
          color="white"
          textAlign="center"
        >
          Verify your email
        </Text>
        <Text fontSize="16px" color="gray.400" textAlign="center">
          You’re account was created successfully. We sent a verification email
          to {userProfile?.email}. Click the link inside to get started.
        </Text>

        <Box position="static" bottom="0" left="0" w="full" right="0">
          <Link as={ReachLink} to="/account" color={'blue.400'}>
            <Button
              bg={'#19AB6A'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              w="full"
              mb="20px"
              type="submit"
            >
              Done
            </Button>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
