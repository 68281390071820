import React from 'react';
import { Helmet } from 'react-helmet';

import sharecardDefault from '@images/share-card.png';

const CustomHelmet = ({ meta }) => {
  const { title = '', description = '', image = sharecardDefault } = meta;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href="https://flex.wtf/" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#131313" />
      <meta name="msapplication-TileColor" content="#131313" />
      <meta name="theme-color" content="#131313" />
      <meta data-react-helmet="true" name="image" content={image}></meta>
      <meta
        data-react-helmet="true"
        property="og:locale"
        content="en_GB"
      ></meta>
      <meta
        data-react-helmet="true"
        property="og:site_name"
        content="Flex"
      ></meta>
      <meta data-react-helmet="true" property="og:image" content={image}></meta>
    </Helmet>
  );
};

export default CustomHelmet;
