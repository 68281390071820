import React, { useEffect, useState, useContext } from 'react';

import { Router, Redirect } from '@reach/router';

import Load from '@components/atoms/Load';
import Helmet from '@components/atoms/Helmet';

import Dashboard from './Dashboard';
import DashboardNFTs from './DashboardNFTs';
import Settings from './Settings';

import { useAuth } from '../../context/UserContext';

function Account() {
  const { user, isSuccess, error } = useAuth();

  return (
    <Load loading={!isSuccess} error={error} height="100vh">
      <Helmet
        meta={{
          title: 'My Account 🖖 | Start Flexing | NFTs',
          description:
            'Flex your profile. Use NFTs, Links and Emojis to create your universal identity. One login for all of Web 3.0 ',
        }}
      />
      {user && (
        <>
          <Router>
            <Dashboard path="/wallets" userProfile={user} />
            <DashboardNFTs path="/nfts" userProfile={user} />
            <Settings path="/settings" userProfile={user} />
            <Redirect from="/" to="/account/wallets" />
          </Router>
        </>
      )}
    </Load>
  );
}

export default Account;
