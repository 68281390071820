import { useState } from 'react';

import { Box, Flex, Text, Link } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';

import tabFlexWallet from '@icons/FlexWalletIcon.svg';
import tabNfts from '@icons/Folder.svg';
import tabSettings from '@icons/Settings.svg';
import tabWallets from '@icons/Future.svg';

import IconText from './IconText';

const icons = [tabFlexWallet, tabNfts, tabWallets, tabSettings];

const NavItem = ({ name, children, iconIndex, to, onClick }) => {
  const [isCurrent, setIsCurrent] = useState(false);
  return (
    <Link
      as={ReachLink}
      to={to}
      onClick={onClick}
      pb="5px"
      pointer="cursor"
      getProps={({ isCurrent }) => {
        setIsCurrent(isCurrent);
        return {
          outline: 'none',
          style: {
            color: isCurrent && 'white',
            borderBottom: isCurrent
              ? '5px solid white'
              : '5px solid transparent',
          },
        };
      }}
    >
      <IconText iconUrl={icons[iconIndex]} active={isCurrent}>
        {children}
      </IconText>
    </Link>
  );
};

export default NavItem;
