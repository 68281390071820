import {
  Box,
  Heading,
  Container,
  Link,
  Button,
  Stack,
  Flex,
  AspectRatio,
  Image,
  Text,
} from '@chakra-ui/react';
import { Link as ReachLink, navigate } from '@reach/router';
import FLEXBrand from '@components/atoms/Brand';

import Helmet from '@components/atoms/Helmet';
import homepageProfiles from '@images/HomepageProfiles.png';

function Homepage({ username }) {
  return (
    <Box
      bg="#000000"
      background="#000000"
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      overflow={'hidden'}
    >
      <Helmet
        meta={{
          title: "🤔 That's not a real flex | 404 | Flex NFT's",
          description:
            'Flex your profile. Use NFTs, Links and Emojis to create your universal identity. One login for all of Web 3.0 ',
        }}
      />
      <Container maxW={'3xl'}>
        <Flex
          direction={'row'}
          justifyContent={'space-between'}
          w="100%"
          h="22px"
          alignItems={'center'}
          py="40px"
        >
          <Box>
            <Link
              _hover={{
                textDecoration: 'none',
              }}
              as={ReachLink}
              to={`/`}
            >
              <FLEXBrand />
            </Link>
          </Box>
          <Box>
            <Link
              _hover={{
                textDecoration: 'none',
              }}
              as={ReachLink}
              to="/login"
            >
              <Button
                textDecoration="none"
                bg={'#19AB6A'}
                color="white"
                fontSize={16}
              >
                Sign in
              </Button>
            </Link>
          </Box>
        </Flex>
      </Container>
      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: '30px' }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={800}
            fontSize={{ base: '6xl', sm: '6xl', md: '10xl', xl: '140px' }}
            lineHeight={{ base: 1, xl: '109.2px' }}
            color="white"
          >
            🤔 That's not a real flex
          </Heading>
          <Text color="white">
            Good news though the username is available. Quick claim it!
          </Text>
          <Link
            _hover={{
              textDecoration: 'none',
            }}
            as={ReachLink}
            to="/signup"
          >
            <Button
              bg={'#1B21B9'}
              _hover={{
                textDecoration: 'none',
              }}
              color="white"
            >
              Create your first FLEX
            </Button>
          </Link>
          <Box
            textAlign={'center'}
            alignItems="center"
            display={'flex'}
            justifyContent={'center'}
          >
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              alignContent={'center'}
              justify={'space-between'}
              maxWidth="250px"
            >
              <Link href="https://flex.wtf/" color={'gray.400'}>
                What is FLEX?
              </Link>
              <Link href="https://flex.wtf/" color={'gray.400'}>
                Terms
              </Link>
              <Link href="https://flex.wtf/" color={'gray.400'}>
                Help
              </Link>
            </Stack>
          </Box>
        </Stack>
      </Container>
      <Box
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        width="100%"
        flex="1"
        overflow={'hidden'}
        pointerEvents="none"
      >
        <AspectRatio
          maxW="full"
          ratio={4 / 3}
          height={{ sm: '200px', md: '400px' }}
        >
          <Image
            src={homepageProfiles}
            alt="FLEX Connect NFT Profiles"
            fit="cover"
            align="top center"
          />
        </AspectRatio>
      </Box>
    </Box>
  );
}

export default Homepage;
