interface IEndpointEnv {
  account: string;
  api: string;
  connect: string;
  signer: string;
  accountManagement: string;
}

interface IEndpoints {
  [key: string]: IEndpointEnv;
}

const defaultEndpoints = {
  account: "https://id-staging.fle.xyz/",
  api: "https://api-staging.fle.xyz/",
  connect: "https://connect.fle.xyz/",
  signer: "https://wallet-staging.fle.xyz/",
  accountManagement:
    "https://connect.fle.xyz/realms/flex-development/account/#/"
};

const endpoints: IEndpoints = {
  default: defaultEndpoints,
  production: defaultEndpoints,
  local: {
    account: "https://id-staging.fle.xyz/",
    api: "https://api-staging.flex.wtf/",
    connect: "https://connect.fle.xyz/",
    signer: "http://localhost:2222/",
    accountManagement:
      "https://connect.fle.xyz/realms/flex-development/account/#/"
  },
  development: {
    api: "https://api-staging.flex.wtf/",
    connect: "https://connect.fle.xyz/",
    account: "https://id-staging.fle.xyz/",
    signer: "http://flex-wallet-staging.web.app/",
    accountManagement:
      "https://connect.fle.xyz/realms/flex-development/account/#/"
  },
  staging: {
    account: "https://id-staging.fle.xyz/",
    api: "https://api-staging.fle.xyz/",
    connect: "https://connect.fle.xyz/",
    signer: "https://wallet-staging.fle.xyz/",
    accountManagement:
      "https://connect.fle.xyz/realms/flex-development/account/#/"
  }
};

export default endpoints;
