import { Controller, useForm } from 'react-hook-form';

import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormHelperText,
  FormErrorMessage,
} from '@chakra-ui/react';

const InputField = ({
  label,
  type,
  help,
  name,
  placeholder,
  rows,
  control,
  rules,
  autoComplete,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState, ...extras }) => {
        return (
          <FormControl isInvalid={fieldState.error && fieldState.isTouched}>
            <FormLabel fontSize={'13px'} htmlFor={name}>
              {label}
            </FormLabel>
            {type == 'textarea' ? (
              <Textarea type={type} {...field} rows={rows} />
            ) : (
              <Input
                type={type}
                autoComplete={autoComplete}
                placeholder={placeholder}
                {...field}
              />
            )}

            {help && <FormHelperText>{help}</FormHelperText>}
            {fieldState.error?.message && (
              <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    ></Controller>
  );
};

export default InputField;
