import { Flex, Box, Text, Stack, Button, Link } from '@chakra-ui/react';
import { useState } from 'react';
import { Link as ReachLink, navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import InputField from '@components/atoms/InputField';
import FormErrorMessage from '@components/atoms/FormErrorMessage';

import InputProfileLinksFIeldArray from '@components/molecules/InputProfileLinksFIeldArray';

import ModalNavBar from '@/components/molecules/ModalScreen/NavBar.jsx';
import EditableInputField from '@components/atoms/EditableInputField';

import { sdk } from '@services/FlexConnectAPI';

import AuthLayout from '@layout/Auth';

function Settings({ userProfile }) {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setError,
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      username: userProfile.username,
      links: userProfile.links,
      description: userProfile.description,
    },
  });
  const queryClient = useQueryClient();

  const accountManagementURL = sdk.getAccountManagementURL();

  const updateWalletMutation = useMutation(
    ({ data }) => {
      sdk.api.updateProfile(data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('profiles::me');
      },
    }
  );

  const onSubmit = async data => {
    try {
      if (data.username !== userProfile.username) {
        // TODO
        // await User.checkUsernameAvailable(data.username);
      }
      // delete data.username;
      await updateWalletMutation.mutate({ data });
    } catch (e) {
      console.log(e);
      if (e.code == 'auth/email-already-in-use') {
        setError('email', { message: 'Email already in use' });
      }

      if (e.code == 'username/taken') {
        return setError('username', { message: e.message });
      }
      setError('general', {
        message: 'Unable to signup. Please check the fields again.',
      });
    }
  };

  return (
    <AuthLayout navbar={<ModalNavBar userProfile={userProfile} />}>
      <form
        onSubmit={e => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Stack mt="40px" spacing={'20px'}>
          <Text fontSize="22px">Profile</Text>
          <FormErrorMessage errors={errors} name="general" />
          <InputField
            control={control}
            label="Username"
            type="text"
            name="username"
            help="Remember that changing your username will also change your profile URL."
            rules={{
              required: true,
              minLength: {
                value: 4,
                message: 'Minimum length should be 4',
              },
            }}
          />
          <InputField
            control={control}
            label="Bio"
            type="textarea"
            name="description"
            rows="4"
            rules={{
              required: {
                value: true,
                message: 'You need to write something here.',
              },
              minLength: {
                value: 4,
                message: 'Minimum length should be 4',
              },
              maxLength: {
                value: 280,
                message: 'Limit of 280 characters',
              },
            }}
          />
          <InputProfileLinksFIeldArray control={control} />
          <Button
            bg={'#19AB6A'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            mt="30px"
            type="submit"
            isLoading={isSubmitting}
          >
            Save
          </Button>

          <Text fontSize="22px">Account</Text>
          <Link href={accountManagementURL} target="_blank">
            Advance Account Management
          </Link>
          <Link as={ReachLink} to="/logout">
            <Button bg="#FC1B0D" color="white">
              Sign out
            </Button>
          </Link>
          <Box mb="20px" />
        </Stack>
      </form>
    </AuthLayout>
  );
}

export default Settings;
