import FlexConnect from '@crowdform/flex-connect-sdk';

export const sdk = new FlexConnect('flex-xyz', {
  // env: process.env.REACT_APP_ACTIVE_ENV == 'dev' ? 'local' : 'development',
  env: 'staging',
  // env: 'local',
});

sdk.init({
  // onLoad: 'check-sso',
  // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  disableAuthInit: true,
});

export const authClient = sdk.authClient();

export const signOut = async () => {
  return sdk.signOut();
};
