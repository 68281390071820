import React from 'react';
import styled, { css, breakpoints } from '@xstyled/styled-components';
import { Spinner } from '@chakra-ui/react';

const Load = ({ loading, error, children, height, loadingComponent }) => {
  if (loading) {
    if (loadingComponent) {
      return loadingComponent;
    }
    return (
      <LoadingContainer height={height}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="#747681"
          size="lg"
        />
      </LoadingContainer>
    );
  }

  if (error)
    return (
      <LoadingContainer>
        <div>{error?.message}</div>
      </LoadingContainer>
    );
  return children;
};

export default Load;

const LoadingContainer = styled.div`
  height: ${props => props.height};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;
