import React, { useEffect } from 'react';
import { VStack, Text, Button, IconButton, Box } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import { useEthers } from '@usedapp/core';

import { sdk } from '@services/FlexConnectAPI';
import { useQuery, useMutation, useQueryClient } from 'react-query';

import Load from '../atoms/Load';

import ConnectedWalletWidget from '../molecules/ConnectedWalletWidget';
import SkeletonWalletItem from '@components/molecules/ConnectedWalletWidget/SkeletonWalletItem';

const WalletList = () => {
  const queryClient = useQueryClient();
  const {
    account,
    activate,
    deactivate,
    error: ethError,
    chainId,
    active,
  } = useEthers();
  console.log('account', account, chainId, ethError, active);
  const { isLoading, error, data, isFetching } = useQuery('wallets', () =>
    sdk.api.getProfileWallets()
  );

  const removeWalletMutation = useMutation(
    id => sdk.api.removeProfileWallet(id),
    {
      onSettled: () => {
        queryClient.invalidateQueries('wallets');
        queryClient.invalidateQueries('nfts');
      },
    }
  );

  const updateWalletMutation = useMutation(
    ({ uid, data }) => {
      sdk.api.updateWallet(uid, data);
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries('wallets');
        queryClient.invalidateQueries('nfts');
      },
    }
  );

  return (
    <Load
      loading={isLoading}
      height="auto"
      loadingComponent={
        <VStack
          overflow="scroll"
          position="relative"
          flex="1"
          display="flex"
          height="100%"
          mx={'-20px'}
        >
          <SkeletonWalletItem />
          <SkeletonWalletItem />
          <SkeletonWalletItem />
        </VStack>
      }
    >
      <>
        <Box position="relative" flex="1" display="flex" height="100%">
          <VStack flex="1" display="flex" mx={'-20px'}>
            {error && <strong>Error: {JSON.stringify(error)}</strong>}

            {!isLoading &&
              data?.data?.map((item, index) => {
                return (
                  <ConnectedWalletWidget
                    key={item.id}
                    uid={item.id}
                    // connectedWallet={account}
                    account={item.attributes.address}
                    name={item.attributes.name || `Wallet ${index}`}
                    updateWalletMutation={updateWalletMutation}
                    removeWalletMutation={removeWalletMutation}
                    type={item.attributes.type}
                    // user={user}
                    // totalCount={item.totalCount}
                    // images={item.images}
                  />
                );
              })}
            {isEmpty(data?.data) && (
              <Text fontSize="16px" align="center" px="15px" color="gray.400">
                Your connected wallets will display here.
              </Text>
            )}
          </VStack>
        </Box>
      </>
    </Load>
  );
};

export default WalletList;
