import styled from 'styled-components';
import { Box, Flex, Text, StackDivider, HStack } from '@chakra-ui/react';

import flexBrand from '../../../assets/icons/FLEX.svg';

import { Link as ReachLink } from '@reach/router';

import IconText from './IconText';

import iconUser from '@icons/User.svg';
import iconSettings from '@icons/Settings.svg';
import iconWallets from '@icons/Future.svg';

const SignupNavBar = ({ step = 0 }) => {
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      py={'19px'}
      px={'20px'}
      // mb={10}
      bg="black"
      color="white"
      justifyContent={'space-between'}
      position={'relative'}
      mt="0"
    >
      <Flex
        direction={'row'}
        justifyContent={'space-between'}
        w="100%"
        h="22px"
        alignItems={'center'}
      >
        <HStack
          divider={<Box border="0" bg="gray.500" width="50px" height="2px" />}
          w="100%"
          spacing="20px"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <IconText iconUrl={iconUser} active={step >= 0}>
            Your details
          </IconText>
          <IconText iconUrl={iconSettings} active={step >= 1}>
            Connect wallets
          </IconText>
          <IconText iconUrl={iconWallets} active={step >= 2}>
            Complete
          </IconText>
        </HStack>
      </Flex>
    </Flex>
  );
};

export default SignupNavBar;
