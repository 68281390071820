import {
  Flex,
  Circle,
  Box,
  Image,
  Badge,
  Text,
  Link,
  AspectRatio,
} from '@chakra-ui/react';

import { get } from 'lodash';

// const openSeaBase =
//   process.env.REACT_APP_ACTIVE_ENV == 'production'
//     ? 'https://opensea.io/assets'
//     : 'https://testnets.opensea.io/assets';

const openSeaBase =
  process.env.REACT_APP_ACTIVE_ENV == 'production'
    ? 'https://app-staging.fle.xyz/assets'
    : 'https://app-staging.fle.xyz/assets';
const getAssetUrl = _data => {
  return `${openSeaBase}/${get(_data, 'attributes.contractAddress')}/${Number(
    get(_data, 'attributes.tokenId')
  )}`;
};

function NFTItem({ data: _data }) {
  const imageUrl = get(_data, 'attributes.nftImage');
  // hidden NFTs without images
  if (!imageUrl) return null;
  return (
    <Flex
      w="full"
      overflow="hidden"
      borderWidth="1px"
      rounded="lg"
      shadow="lg"
      position="relative"
    >
      <Link
        target="_blank"
        href={getAssetUrl(_data)}
        _hover={{ textDecoration: 'none' }}
        width="100%"
      >
        <Box w="full">
          {/* {data.isNew && (
            <Circle
              size="10px"
              position="absolute"
              top={2}
              right={2}
              bg="red.200"
            />
          )} */}
          <AspectRatio w="full" ratio={1}>
            {imageUrl ? (
              <Image
                w="full"
                src={imageUrl}
                alt={`Picture of ${get(_data, 'attributes.title')}`}
                roundedTop="lg"
                width="100%"
                objectFit="cover"
              />
            ) : (
              <Text>Image Not Available</Text>
            )}
          </AspectRatio>

          <Flex
            mt="1"
            pt="10px"
            px="15px"
            pb="15px"
            justifyContent="space-between"
            alignContent="center"
          >
            <Box
              fontSize="17px"
              fontWeight="semibold"
              as="h4"
              lineHeight="tight"
              isTruncated
            >
              {get(_data, 'attributes.title')}
            </Box>
          </Flex>
        </Box>
      </Link>
    </Flex>
  );
}

export default NFTItem;
