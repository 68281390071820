import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHover } from 'usehooks-ts';

import { Box, Text } from '@chakra-ui/react';

import { EditIcon, CheckCircleIcon } from '@chakra-ui/icons';

import InputField from '@/components/atoms/InputField';

const EditableForm = ({
  _onSubmit,
  name,
  defaultValues,
  onSubmitSuccess,
  label,
  help,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const onSubmit = async data => {
    if (data.name == name) return onSubmitSuccess();
    // submit function
    await _onSubmit(data);
    onSubmitSuccess();
  };
  return (
    <Box display={'flex'} alignItems="center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField name={name} control={control} label={label} help={help} />
      </form>
      <CheckCircleIcon
        w={3}
        h={3}
        ml="10px"
        onClick={() => handleSubmit(onSubmit)}
      />
    </Box>
  );
};

const DisplayField = ({ label, type, name, control, rules }) => {
  return (
    <Text fontSize="22px" fontWeight={'700'}>
      {name}
    </Text>
  );
};

const EditableInputField = ({ onSubmit, name, defaultValues, ...props }) => {
  const [editable, isEditable] = useState(false);
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  return (
    <Box>
      {editable ? (
        <Box display={'flex'} alignItems="center">
          <EditableForm
            _onSubmit={onSubmit}
            name={name}
            onSubmitSuccess={() => isEditable(false)}
            defaultValues={defaultValues}
            {...props}
          />
        </Box>
      ) : (
        <Box
          ref={hoverRef}
          display={'flex'}
          alignItems="center"
          onClick={() => isEditable(!editable)}
        >
          <DisplayField name={defaultValues[name]} />
          {isHover && <EditIcon w={3} h={3} ml="10px" />}
        </Box>
      )}
    </Box>
  );
};

export default EditableInputField;
