import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: 'ABCWhyteInktrap, sans-serif',
    body: 'ABCWhyte, sans-serif',
  },
});

export default theme;
