import React, { useEffect } from 'react';

import {
  Flex,
  Box,
  Container,
  Stack,
  VStack,
  Center,
  Heading,
  Text,
  SimpleGrid,
  Link,
} from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';
import FLEXBrand from '@components/atoms/Brand';
import Helmet from '@components/atoms/Helmet';

import { get } from 'lodash';

export default function SplitScreen({ children, data: dataRequest, isLoaded }) {
  const data = get(dataRequest, 'data', {});
  const links = get(data, 'links', []);
  return (
    <SimpleGrid
      columns={{ base: 1, lg: 2 }}
      spacing={{ base: 8, md: 10 }}
      width="100%"
    >
      {isLoaded ? (
        <Helmet
          meta={{
            title: `${get(data, 'username')}  | Fle.xyz`,
            description: get(data, 'description'),
          }}
        />
      ) : (
        <Helmet
          meta={{
            title: '🖖 Flexing... | NFTs',
            description:
              'Flex your profile. Use NFTs, Links and Emojis to create your universal identity. One login for all of Web 3.0 ',
          }}
        />
      )}
      <Flex
        color="white"
        bg="#000"
        width="100%"
        minHeight={{ lg: '100vh' }}
        py={{ base: 35, md: 24 }}
        px={{ base: '30px', lg: '70px' }}
      >
        <Center flex="1">
          <Stack align={'left'} width="100%">
            <Skeleton
              isLoaded={isLoaded}
              height="60px"
              minWidth="260px"
              width="90%"
            >
              <Heading fontSize={'45px'}>{get(data, 'username')}</Heading>
            </Skeleton>
            <SkeletonText
              isLoaded={isLoaded}
              spacing="2"
              width="80%"
              noOfLines={4}
              minWidth="260px"
              width="90%"
            >
              <Text fontSize={'lg'} color={'white'}>
                {get(data, 'description')}
              </Text>
            </SkeletonText>

            <Box display="block" py={{ base: '20px', md: '40px' }}>
              <SkeletonText
                noOfLines={1}
                isLoaded={isLoaded}
                width={{ base: !isLoaded && '30%' }}
                minWidth="50px"
              >
                <Text fontSize={'sm'} fontSize="15px" color={'gray.600'}>
                  {/* {get(data, 'meta.count', 0)} NFTs •{' '}
                  {get(data, 'profile.wallets.length', 0)} wallets linked */}
                </Text>
              </SkeletonText>
            </Box>
            <VStack spacing="20px" alignItems={'flex-start'}>
              {isLoaded &&
                Array.isArray(links) &&
                links.map(({ url, title }) => {
                  return (
                    <Link
                      key={url}
                      href={`${url}?ref=fle.xyz/${get(data, 'username')}`}
                      target="_blank"
                      _hover={{ textDecoration: 'none' }}
                    >
                      <Flex>
                        <Box mr="10px" fontSize="24px">
                          🔗
                        </Box>
                        <Box>
                          <Text fontSize="17px">{title}</Text>
                          <Text fontSize="13px" color="gray.500">
                            {url}
                          </Text>
                        </Box>
                      </Flex>
                    </Link>
                  );
                })}
            </VStack>
          </Stack>
        </Center>
        <Box position={'absolute'} bottom="50px">
          <Link as={ReachLink} to={`/`}>
            <FLEXBrand />
          </Link>
        </Box>
      </Flex>
      <Flex minHeight={{ lg: '100vh' }}>
        <Box
          flex="1"
          px={{ base: '20px' }}
          pr={{ md: 50 }}
          overflow="scroll"
          height={{ lg: '100vh' }}
        >
          {children}
        </Box>
      </Flex>
    </SimpleGrid>
  );
}
