import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';

import AuthLayout from '../../layout/Auth';
import WalletList from '@components/molecules/WalletList';

import ConnectWallet from '@/components/molecules/ConnectWallet';

import ModalNavBar from '@/components/molecules/ModalScreen/NavBar.jsx';

function Dashboard({ userProfile }) {
  return (
    <AuthLayout
      navbar={<ModalNavBar userProfile={userProfile} />}
      footer={<ConnectWallet />}
    >
      <Box mt="20px" />
      <WalletList />
    </AuthLayout>
  );
}

export default Dashboard;
