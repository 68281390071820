import { Link as ReachLink, navigate } from '@reach/router';

import { useEffect } from 'react';

import { useAuth } from '../context/UserContext';

export default function Logout() {
  const { user, signOut } = useAuth();

  useEffect(() => {
    if (user) {
      signOut();
    }
    return navigate('/');
  }, []);

  return <div>Page</div>;
}
