import { useEffect, useContext } from 'react';

import { Router, Redirect } from '@reach/router';

import AuthLayout from '@/layout/Auth';

import SignupNavBar from '@components/molecules/ModalScreen/SignupNavBar';

import { useLocation } from '@reach/router';

import Helmet from '@components/atoms/Helmet';

import { UserContext } from '../../context/UserContext';

import ManageWallets from './ManageWallets';
import Complete from './Complete';

export default function SignupScreen() {
  const { user, isLoading } = useContext(UserContext);
  const { pathname } = useLocation();

  const isCompletePage = pathname.includes('/confirm');

  const steps = () => {
    if (pathname.includes('/wallets')) return 1;
    if (pathname.includes('/confirm')) return 2;
    return 0;
  };

  return (
    <AuthLayout
      pageLayout
      bg={'#000'}
      innerBg={steps() === 2 ? '#000' : '#fff'}
      title="Create account"
      hideBrandHeader
      navbar={<SignupNavBar step={steps()} />}
    >
      <Helmet
        meta={{
          title: 'Sign up 🖖 | Start Flexing | NFTs',
          description:
            'Flex your profile. Use NFTs, Links and Emojis to create your universal identity. One login for all of Web 3.0 ',
        }}
      />
      <Router>
        {user && <ManageWallets path="/wallets" userProfile={user} />}
        {user && <Complete path="/confirm" userProfile={user} />}
      </Router>
    </AuthLayout>
  );
}
