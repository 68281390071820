/**
 * Convenient method to open a popup in the middle of a screen
 *
 * @param {string} url
 * @param {int} w - Width of popup
 * @param {int} h - Height of popup
 * @returns {Object}
 */

declare global {
  interface Window {}
}

const openPopup = function openPopup(
  url: string,
  w: number,
  h: number,
  noFocus: boolean
) {
  if (typeof window == "undefined") {
    // Client-side-only code
    return null;
  }

  // Fixes dual-screen position                         Most browsers      Firefox
  const dualScreenLeft = window.screenLeft || window.screenX;
  const dualScreenTop = window.screenTop || window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  let oauthWindow = window.open(
    url,
    "oauthFlex",
    "scrollbars=yes, width=" +
      w +
      ", height=" +
      h +
      ", top=" +
      top +
      ", left=" +
      left +
      "alwaysLowered=yes" +
      "titlebar=no,toolbar=no"
  );

  // Puts focus on the newWindow
  // @ts-ignore
  if (!noFocus && window.focus) {
    oauthWindow?.focus();
  }

  return oauthWindow;
};

export { openPopup };
