//@ts-nocheck
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import PopupWindow from "./popup";

import flexLogo from "./flexLogo";

export default (signer, tokenProvider, options) => {
  const popup = new PopupWindow(signer);
  const providerOptions = {
    // Example with injected providers
    injected: {
      // display: {
      //   // logo: 'data:image/gif;base64,INSERT_BASE64_STRING',
      //   name: 'MetaMask Injected',
      //   description: 'Connect with the provider in your Browser',
      // },
      package: null
    },
    // Example with WalletConnect provider
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "7017a81cd75d42439e97b7ac76d4687b", // required
        rpc: {
          137: "https://matic-mainnet.chainstacklabs.com"
        }
      }
    },
    /* See Provider Options Section */
    "custom-flex-connect": {
      display: {
        logo: `data:image/gif;base64,${flexLogo}`,
        name: "FlexConnect",
        description: "Connect to your Flex account"
      },
      package: WalletConnectProvider,
      options: {
        infuraId: "7017a81cd75d42439e97b7ac76d4687b",
        qrcode: false
      },
      connector: async (ProviderPackage, options) => {
        const provider = new ProviderPackage(options);

        provider.connector.on("display_uri", (err, payload) => {
          const uri = payload.params[0];
          const encodedUrl = encodeURI(uri);
          popup.setSession(encodedUrl);
          popup.open();
          return null;
        });

        // Enable session (triggers QR Code modal)
        await provider.enable();

        // wrap the send in popup open
        const _providerHandleOtherRequests = provider.handleOtherRequests;
        provider.handleOtherRequests = args => {
          console.log("---", args);
          // don't open model on block reads
          // if (!args?.method.startsWith("eth_")) {
          popup.open();
          // }

          return _providerHandleOtherRequests(args);
        };

        return provider;
      }
    }
  };

  const Web3 = {
    modal: new Web3Modal({
      // network: 'mainnet', // optional
      cacheProvider: false, // optional
      providerOptions, // required
      ...options
    }),
    signer: popup
  };

  return Web3;
};
