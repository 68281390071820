import { Box, Flex, Text, Link } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';

import styled from 'styled-components';

const IconText = ({ iconUrl, children, active = false }) => (
  <Box
    display={'flex'}
    flexDirection="column"
    opacity={active ? 1 : 0.5}
    alignItems="center"
  >
    <Icon iconUrl={iconUrl} />
    <Text fontSize="13px" textDecoration={'none'} whiteSpace="nowrap">
      {children}
    </Text>
  </Box>
);

const Icon = styled.div`
  background-image: url(${props => props.iconUrl});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 30px;
  height: 30px;
  display: block;
  margin-bottom: 5px;
`;

export default IconText;
