import Web3 from 'web3';
import { sdk } from '../FlexConnectAPI';

const Web3Modal = sdk.web3().modal;

export const EthereumEvents = {
  CONNECT: 'connect',
  DISCONNECT: 'disconnect',
  ACCOUNTS_CHANGED: 'accountsChanged',
  CHAIN_CHANGED: 'chainChanged',
};

class Web3Error extends Error {
  constructor(message, ...params) {
    super(...params);
    this.name = 'FlexWeb3Error';
    this.message = message || params.message;
    this.date = new Date();
  }
}

const getWalletMeta = provider => {
  if (provider.isMetaMask) {
    return { name: 'METAMASK', description: 'Injected Extension' };
  }

  if (provider.isWalletConnect) {
    return { name: 'WALLETCONNECT', description: 'WalletConnect External' };
  }

  return provider.walletMeta
    ? {
        name: provider.walletMeta.name.toUpperCase(),
        description: provider.walletMeta.description,
      }
    : {
        name: 'UNKNOWN',
        description: '',
      };
};

class FlexConnectWeb3 {
  constructor() {}

  static web3Modal = Web3Modal;
  static async enable(options) {
    const provider = options?.provider || (await this.web3Modal.connect());

    const web3 = new Web3(provider);

    this.walletMeta = getWalletMeta(provider);

    this.web3 = web3;
    return web3;
  }

  static async authenticate(options) {
    try {
      const web3 = await this.enable(options);

      const accounts = await web3.eth.getAccounts();

      const accountsLower = accounts.map(v => v.toLowerCase());
      const [ethAddress] = accountsLower;
      if (!ethAddress) throw new Error('Address not found');

      const { data } = await sdk.api.getWalletChallenge(ethAddress);

      const params = [ethAddress, JSON.stringify(data.challenge)];

      const signature = await web3.currentProvider.request({
        method: 'eth_signTypedData_v4',
        params,
        from: ethAddress,
      });

      if (!signature) throw new Error('Data not signed');

      await sdk.api.postWalletChallenge(ethAddress, {
        signature,
        hash: data.challenge.message.challenge,
        name: this.walletMeta.description,
        type: this.walletMeta.name,
      });

      // Close provider session
      return accounts;
    } catch (e) {
      console.log('error', e);
      // catch undefined error
      if (e.name == 'Error' || !e.name) {
        throw new Web3Error(e.message, e);
      }
      // defined error and handle accordingly in SDK
      throw e;
    }
  }

  static async resetApp() {
    const web3 = this.web3;
    if (web3 && web3.currentProvider && web3.currentProvider.close) {
      await web3.currentProvider.close();
    }
    await Web3Modal.clearCachedProvider();
  }

  static async subscribeProvider(provider) {
    if (!provider.on) {
      return;
    }
    provider.on('disconnect', () => {
      this.resetApp();
    });
    provider.on('accountsChanged', async accounts => {});

    provider.on('chainChanged', async networkId => {});
  }
}

export default FlexConnectWeb3;
