import React, { useEffect } from 'react';
import { VStack, ButtonGroup, Button, Text, useToast } from '@chakra-ui/react';
import { useQueryClient } from 'react-query';

import { AddIcon } from '@chakra-ui/icons';

import FlexConnectWeb3 from '../../services/FlexConnectWeb3/FlexConnectWeb3';

const ConnectWallet = ({ alreadyConnectedWallets = [], account, buttonBg }) => {
  const queryClient = useQueryClient();
  const isNewWallet = !alreadyConnectedWallets.includes(account);
  const toast = useToast();
  const flex = new FlexConnectWeb3();

  const bg = buttonBg || '#19AB6A';

  return (
    <VStack>
      <ButtonGroup
        size="sm"
        isAttached
        onClick={async () => {
          if (!isNewWallet) {
            toast({
              title: `You have already connected this wallet.`,
              description: 'Select a different wallet to connect to FLEX',
              status: 'warning',
              isClosable: true,
            });
          }

          try {
            toast({
              title: `Pending Wallet Request`,
              description: 'Approve or reject request using your wallet',
              status: 'info',
              isClosable: false,
            });
            await FlexConnectWeb3.authenticate();
            queryClient.invalidateQueries('wallets');
          } catch (e) {
            if (e.name == 'FlexWeb3Error') {
              return toast({
                title: `Unable to verify the wallet. Please try again.`,
                description: e?.message,
                status: 'error',
                isClosable: true,
              });
            }
            // issue server side
            toast({
              title: `Unable to verify the wallet. Please try again.`,
              description: e?.message,
              status: 'error',
              isClosable: true,
            });
          }
        }}
        rounded="full"
      >
        <Button mr="-px" color="white" bg={bg} leftIcon={<AddIcon />}>
          {isNewWallet ? 'Add external wallet' : 'Wallet already connected'}
        </Button>
      </ButtonGroup>
      {!isNewWallet && (
        <Text>Select a new wallet to connect in the MetaMask</Text>
      )}
    </VStack>
  );
};

export default ConnectWallet;
