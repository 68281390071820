import React, { useEffect } from 'react';

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { navigate } from '@reach/router';

import FlexBrandHeader from '../components/molecules/ModalScreen/FlexBrandHeader';
import FLEXBrand from '@components/atoms/Brand';

export default function SimpleCard({
  title,
  subtitle,
  children,
  navbar,
  footer,
  pageLayout,
  bg,
  innerBg = 'white',
  hideBrandHeader,
}) {
  return (
    <Flex
      minH={{ sm: '100vh' }}
      align={'center'}
      justify={'center'}
      bg={bg || 'gray.50'}
      flexDirection="column"
    >
      {pageLayout && title && (
        <Box>
          <Stack spacing={'8px'} pt="60px" pb={'30px'} align={'center'}>
            <FLEXBrand mb="30px" />
            <Heading color={'white'} fontSize={'30px'}>
              {title}
            </Heading>
            <Text fontSize={'16px'} color={'gray.400'}>
              {subtitle}
            </Text>
          </Stack>
        </Box>
      )}

      <Box
        mx={'auto'}
        w="100%"
        maxW={'600px'}
        h={{ sm: '100%', md: '600px' }}
        rounded={{ md: 'lg' }}
        bg={innerBg}
        boxShadow={{ sm: 'lg' }}
        overflow="hidden"
        display="flex"
        flexDirection="column"
        position="relative"
      >
        {!hideBrandHeader && <FlexBrandHeader />}
        {navbar}
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          flex="1"
          overflow={'scroll'}
        >
          <Box px={'20px'} flex="1" minHeight={'100%'}>
            {!pageLayout && title && (
              <Stack spacing={'8px'} pt="60px" pb={'20px'} align={'center'}>
                <Heading fontSize={'30px'}>{title}</Heading>
                <Text fontSize={'16px'} color={'gray.400'}>
                  {subtitle}
                </Text>
              </Stack>
            )}

            <Box
              display="flex"
              position="relative"
              flexDirection="column"
              flex="1"
              minHeight={{ base: '100%' }}
            >
              {children}
            </Box>
          </Box>
        </Box>
        {footer && (
          <Box
            position={{ base: 'fixed', md: 'absolute' }}
            bottom="20px"
            right="20px"
          >
            {footer}
          </Box>
        )}
      </Box>
    </Flex>
  );
}
