import { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useQuery } from 'react-query';

import PublicProfileNFTList from '@components/molecules/PublicProfileNFTList';

import ProfileLayout from '@layout/ProfileLayout';

import { sdk } from '@services/FlexConnectAPI';

function PublicProfile({ username }) {
  const { isLoading, error, data, isFetching } = useQuery(
    ['profile', username],
    () => sdk.api.getProfileByUsername(username)
  );

  const nftQuery = useQuery(['profile-nfts', username], () =>
    sdk.api.getProfileByUsernameNFTs(username, {
      'pagination[pageSize]': 200,
    })
  );

  useEffect(() => {
    if (error) {
      navigate(`/404?username=${username}`);
    }
  }, [error]);

  return (
    <ProfileLayout isLoaded={!isLoading} data={data}>
      <PublicProfileNFTList nftQuery={nftQuery} />
    </ProfileLayout>
  );
}

export default PublicProfile;
