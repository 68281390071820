import { SimpleGrid } from '@chakra-ui/react';
import Load from '@components/atoms/Load';
import NFTItem from '@components/atoms/NFTItem';

function PublicProfileNFTList({
  nftQuery: { isLoading, error, data, isFetching },
}) {
  return (
    <>
      <Load loading={isLoading} height="100vh">
        <SimpleGrid
          columns={{ base: 2, md: 3 }}
          mt={{ md: '20px' }}
          spacing={{ base: 3, md: 2 }}
        >
          {data?.data.map((item, index) => (
            <NFTItem key={index} data={item} />
          ))}
        </SimpleGrid>
      </Load>
    </>
  );
}

export default PublicProfileNFTList;
