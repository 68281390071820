import React, {
  createContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { signOut as flexSignOut } from '@services/FlexConnectAPI';
import { useKeycloak } from '@react-keycloak/web';

import { sdk } from '@services/FlexConnectAPI';
// create context
const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  // the value that will be given to the context
  const { keycloak, initialized } = useKeycloak();
  const [user, setUser] = useState();
  // const [isLoading, setIsLoading] = useState(true);
  const [loadingData, setLoadingData] = useState({ hasAttemptedAuth: false });

  const {
    isLoading,
    error,
    isLoadingError,
    data: userData,
    isFetching,
    isSuccess,
    refetch: fetchUser,
  } = useQuery('profiles::me', () => sdk.api.getProfile(), { enabled: false });

  useEffect(() => {
    if (userData) setUser(userData);
  }, [userData]);

  // sign out the user, memoized
  const signOut = useCallback(() => {
    setUser(null);
    flexSignOut();
  }, []);

  // fetch a user from a fake backend API
  useEffect(() => {
    if (keycloak.authenticated) {
      fetchUser();
    }

    setLoadingData({ hasAttemptedAuth: true });
  }, [keycloak.authenticated]);

  // memoize the full context value
  const contextValue = useMemo(
    () => ({
      auth: {
        setUser: setUser,
      },
      user,
      isLoading,
      error,
      isLoadingError,
      loadingData,
      isSuccess,
      refetchUserProfile: fetchUser,
      signOut,
    }),
    [user, isSuccess, signOut]
  );

  return (
    // the Provider gives access to the context to its children
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const useAuth = () => useContext(UserContext);

export { UserContext, UserContextProvider, useAuth };
