import { openPopup } from "../utils/popup";

class PopupWindow {
  url: string;
  sessionId: string = "";
  public popupWindow?: any;
  constructor(url: string) {
    this.url = url;
    return this;
  }
  isOpen() {
    return this.popupWindow && !this.popupWindow?.closed;
  }
  setSession(sessionId: string) {
    this.sessionId = sessionId;
  }
  open() {
    if (this.isOpen()) {
      this.popupWindow.focus();
      return null;
    }

    const searchParams = new URLSearchParams();
    let finalUrl = `${this.url}`;

    if (this.sessionId) {
      searchParams.set("sessionId", this.sessionId);
      finalUrl = finalUrl + `?${searchParams}`;
    }

    this.popupWindow = openPopup(finalUrl, 360, 600, true);
    return this.popupWindow;
  }
  close() {
    this.popupWindow?.close();
    return this.popupWindow;
  }
  hide() {
    this.popupWindow.blur();
    window.focus();
  }
}

export default PopupWindow;
