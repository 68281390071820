// @ts-nocheck
import Keycloak from "keycloak-js";

import Account from "./account/";
import Api from "./api";
import web3 from "./web3";
import contracts from "./contracts";

import endpoints from "./constants";

const getEnv = (env = "default") => endpoints[env];

class FlexConnect {
  public auth: object;
  public account: object;
  public authConfig: object;
  public initPackages: void;

  constructor(clientId: string, options: {}) {
    clientId = clientId;

    const environment = getEnv(options.env);

    this.authConfig = {
      clientId: clientId,
      realm: "flex-development",
      url: environment.connect,
      "ssl-required": "external",
      "public-client": "true"
    };

    this.environment = environment;

    // setup default packages
    this.auth = () => new Keycloak(this.authConfig);
    this.api = () => new Api(environment.api, clientId, this.auth);
    this.account = () => new Account(environment.account, clientId, this.auth);

    // disable web3 for SSR
    if (typeof window !== "undefined") {
      this.web3 = options => web3(environment.signer, this.auth, options);
    }

    return this;
  }

  getContract(contract) {
    // TODO: dynamic load
    return contracts[contract];
  }
  configurePackage(key, options) {
    this[key] = options;
  }

  initPackages(packagesEnabled = []) {
    // default enable all packages
    const packages =
      packagesEnabled.length > 0 ? packagesEnabled : ["auth", "api", "account"];

    packages.map(key => {
      if (typeof this[key] == "function") {
        this[key] = this[key]();
      }
    });

    this.loadPackages = true;

    this.registerListeners();
  }

  async init(options = {}) {
    if (this.hasInit) {
      console.error("Flex: Already initialized");
      return;
    }
    this.initPackages();
    if (!this.loadPackages) {
      throw Error("Flex: Packages have not be loaded");
    }

    if (!options.disableAuthInit) {
      // await this.popup.authorize();
      await this.auth.init({
        onLoad: options?.onLoad || "check-sso",
        // allow pass through of login
        refreshToken: options?.refreshToken,
        token: options?.token,
        silentCheckSsoRedirectUri: options?.silentCheckSsoRedirectUri
      });
    }
    this.fire("init");
    this.hasInit = true;
  }
  async signIn() {
    return this.auth.login();
  }

  async getProfile() {
    return this.auth.loadUserProfile();
  }
  async register() {
    return this.auth.register();
  }
  signOut() {
    this.auth.logout();
  }

  fire(eventName, o) {
    if (typeof window === "undefined") return null;
    console.log("fire", eventName, o);
    const event = new CustomEvent(eventName, { detail: o });
    document.dispatchEvent(event);
  }

  registerListeners() {
    this.auth.onAuthSuccess = async () => {
      const user = await this.getProfile();
      this.fire("onAuthSuccess", { user });
    };
  }

  authClient() {
    return this.auth;
  }

  getAccountManagementURL() {
    return this.environment["accountManagement"];
  }
}

export default FlexConnect;
