import React, { useEffect } from 'react';
import { Box, Tag, Text } from '@chakra-ui/react';

import styled from 'styled-components';

import metaMask from '@icons/MetaMask.svg';
import flexConnect from '@icons/flexconnect.svg';
import walletConnect from '@icons/walletconnect.svg';

const icons = {
  METAMASK: metaMask,
  'FLEXCONNECT WALLET': flexConnect,
  WALLETCONNECT: walletConnect,
};

const ConnectorTag = ({ images, connector, totalCount = 0 }) => {
  return (
    <Box display={'flex'} alignItems="center">
      <Tag bg="gray.100" px="8px" py="12px">
        <Icon type={connector} />
        <Text ml="5px" fontSize="13px" color="gray.400">
          {connector}
        </Text>
      </Tag>
    </Box>
  );
};

const Icon = styled.div`
  background-image: url(${({ type }) => icons[type]});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
`;

export default ConnectorTag;
