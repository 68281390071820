import { openPopup } from "../utils/popup";

export const NFTS = `account/nfts`;
export const WALLETS = `account/wallets`;
export const PROFILE = `account`;
export const SETTINGS = `account/settings`;

type Endpoints = {
  [key: string]: string;
};

class Account {
  endpoint: string;
  popupWidth: number = 865;
  popupHeight: number = 692;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
  openFlexPopup(URL: string) {
    const endpoints: Endpoints = {
      NFTS,
      WALLETS,
      PROFILE,
      SETTINGS
    };

    openPopup(
      `${this.endpoint}${endpoints[URL]}`,
      this.popupWidth,
      this.popupHeight,
      false
    );
  }
}

export default Account;
