import { SimpleGrid, Text } from '@chakra-ui/react';
import { isEmpty } from 'lodash';

import NFTItem from '../atoms/NFTItem';

const WalletList = ({ data }) => {
  const getAllNFTs = () => {
    const items = [];
    if (data?.data) {
      data?.data.forEach(doc => {
        items.push(<NFTItem key={doc.id} data={doc} />);
      });
    }

    return items;
  };

  return (
    <>
      <>
        {isEmpty(data?.data) && (
          <Text
            fontSize="16px"
            align="center"
            px="15px"
            mt="20px"
            color="gray.400"
          >
            Your NFT's from your connected wallets will display here.
          </Text>
        )}
      </>
      <SimpleGrid columns={3} mt="20px" spacing={2}>
        {getAllNFTs()}
      </SimpleGrid>
    </>
  );
};

export default WalletList;
