import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import { hydrate, render } from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

import {
  defaultTheme,
  ThemeProvider,
  Preflight,
} from '@xstyled/styled-components';

import './assets/font.css';

const theme = {
  ...defaultTheme,
  // Customize your theme here
};

const ApplicationRoot = () => (
  <StrictMode>
    <ColorModeScript />
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </StrictMode>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  // hydrate(<ApplicationRoot />, rootElement);
  render(<ApplicationRoot />, rootElement);
} else {
  render(<ApplicationRoot />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
