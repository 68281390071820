// @ts-nocheck

import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
  Method
} from "axios";

const NO_AUTH_REQUIRED = false;

interface FlexAPIError {
  new (message: string, params: any): FlexAPIErrorInterface;
}

export interface FlexAPIErrorInterface {
  message: string;
  date: Date;
  details: object;
  status: number;
}

enum AxiosMethod {
  get = "get",
  put = "put",
  delete = "delete",
  post = "post"
}

type Nullable<T> = T | null | undefined;

class FlexAPIError extends Error implements FlexAPIErrorInterface {
  public message: string;
  public date: Date;
  public details: object;
  public status: number;
  constructor(message: Nullable<string>, params?: any) {
    super(params);
    this.name = "FlexAPIError";
    this.message = message || params.message;
    this.date = new Date();
    this.details = params.details;
    this.status = params.status;
  }
}

class Api {
  private http: AxiosInstance;

  constructor(baseURL: string, clientId: string, tokenProvider?: any) {
    this.http = axios.create({
      baseURL: baseURL.endsWith("/")
        ? baseURL.substring(0, baseURL.length - 1)
        : baseURL
    });

    if (tokenProvider) {
      this.http.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig => {
          let bearerToken = tokenProvider.token;
          let isAuthenticated =
            tokenProvider.authenticated && tokenProvider.token;

          if (!bearerToken && config.withCredentials) {
            console.log(
              "FlexConnect:: Not authenticated, set withCredentials: false to dismiss for this API call"
            );
            // throw new FlexAPIError("Not authenticated");
          }
          config.headers = config.headers ?? {};

          if (isAuthenticated) {
            config.headers["Authorization"] = "Bearer " + bearerToken;
          }
          return config;
        }
      );
    }

    return this;
  }

  ////////////////////////
  // Profile Scoped API //
  ////////////////////////
  public getProfile = () => {
    return this.processResponse(this.http.get("/api/users/me"));
  };

  public updateProfile = (data: object) => {
    return this.processResponse(
      this.http.put("/api/profiles/me", {
        data
      })
    );
  };

  public getProfileByUsername = (username: string, params: object) => {
    return this.processResponse(
      this.http.get(`/api/profiles/${username}`, {
        withCredentials: NO_AUTH_REQUIRED,
        params
      })
    );
  };

  public getProfileByUsernameNFTs = (username: string, params: object) => {
    return this.processResponse(
      this.http.get(`/api/profiles/${username}/nfts`, {
        withCredentials: NO_AUTH_REQUIRED,
        params
      })
    );
  };

  // NFTs
  public getProfileNFTs = (params?: object) => {
    return this.processResponse(
      this.http.get("/api/profiles/me/nfts", {
        params
      })
    );
  };

  // TEMPORARY
  public updateNFT = (id: number, data: any) => {
    console.info("TEMPORARY API: updateNFT");
    return this.processResponse(
      this.http.put(`/api/nft-models/${id}`, { data })
    );
  };

  // Wallets
  public getProfileWallets = (params: object) => {
    return this.processResponse(
      this.http.get("/api/profiles/me/wallets", {
        params
      })
    );
  };

  public getWalletChallenge = (walletAddress: string) => {
    return this.processResponse(
      this.http.get(`/api/wallet/${walletAddress}/verify`)
    );
  };

  public postWalletChallenge = (walletAddress: string, data: any) => {
    return this.processResponse(
      this.http.post(`/api/wallet/${walletAddress}/verify`, {
        data
      })
    );
  };

  public updateWallet = (id: string, data: any) => {
    return this.processResponse(
      this.http.put(`/api/wallets/${id}`, {
        data
      })
    );
  };

  public removeProfileWallet = (id: string) => {
    return this.processResponse(
      this.http.delete(`/api/profiles/me/wallets/${id}`)
    );
  };

  /////////////////
  // General API //
  /////////////////
  public getUsersWallets = (params: object) => {
    return this.processResponse(
      this.http.get("/api/wallets", {
        params
      })
    );
  };

  public getUsersWallet = (id: string, params: object) => {
    return this.processResponse(
      this.http.get(`/api/wallets/${id}`, {
        params
      })
    );
  };

  // custom request end point to be used for bearer token
  public request = (
    method: AxiosMethod,
    url: string,
    config?: AxiosRequestConfig
  ) => {
    return this.processResponse(this.http[method](url, config));
  };

  // NFTS
  public getNFTs = (params: object) => {
    return this.processResponse(
      this.http.get("/api/nft-models", {
        params
      })
    );
  };

  public getNFT = (id: string) => {
    return this.processResponse(this.http.get(`/api/nft-models/${id}`)); // AxiosResponse
  };

  private processResponse<T>(axiosPromise: AxiosPromise<T>): Promise<T> {
    return new Promise<T>((resolve: any, reject: any) => {
      axiosPromise
        .then((axiosRes: AxiosResponse) => {
          return resolve(axiosRes.data);
        })
        .catch((error: AxiosError) => {
          if (error.response && error.response.data) {
            const _error = new FlexAPIError(null, error.response.data.error);
            reject(_error);
          } else if (error.message) {
            console.log("error.message", error.message);
            let code =
              error.message.indexOf("authenticat") >= 0
                ? "auth.error"
                : "unknown.error";
            reject([{ code: code, message: error.message }]);
          } else {
            reject([
              { code: "unknown.error", message: "An unknown error occured" }
            ]);
          }
        });
    });
  }
}

export default Api;

enum TxStatus {
  UNKNOWN = "UNKNOWN",
  PENDING = "PENDING",
  FAILED = "FAILED",
  SUCCEEDED = "SUCCEEDED"
}

export interface RestApiResponseTxStatus {
  hash: string;
  status: TxStatus;
  confirmations: number;
  blockHash: string;
  blockNumber: number;
  hasReachedFinality: boolean;
}

export interface RestApiResponseError {
  code: string;
  message: string;
}

export interface RestApiResponse<T> {
  success: boolean;
  errors: RestApiResponseError[];
  result: T;
}
