import styled from 'styled-components';
import { Box, Flex, Text, Link } from '@chakra-ui/react';

import { Link as ReachLink } from '@reach/router';

import FLEXBrand from '@components/atoms/Brand';

const FlexBrandHeader = ({}) => {
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      py={'19px'}
      px={'20px'}
      // mb={10}
      bg="black"
      color="white"
      justifyContent={'space-between'}
      position={'relative'}
    >
      <Flex
        direction={'row'}
        justifyContent={'space-between'}
        w="100%"
        h="22px"
        alignItems={'center'}
      >
        <Box>
          <Link as={ReachLink} to={`/`}>
            <FLEXBrand />
          </Link>
        </Box>
        <Box>
          <Text fontSize={16}>One login for all of Web 3.0</Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default FlexBrandHeader;
