import { Flex, Box, Stack, Button, Link, Text } from '@chakra-ui/react';

import { Link as ReachLink } from '@reach/router';
import WalletList from '@components/molecules/WalletList';
import ConnectWallet from '@components/molecules/ConnectWallet';

export default function SignupCard({ userProfile }) {
  return (
    <Box flex="1">
      <Stack spacing={4} mt="40px">
        <Text fontSize="30px" fontWeight={'700'} textAlign="center">
          Connect wallets
        </Text>
        <Text fontSize="16px" color="gray.400" textAlign="center">
          Connect all the wallets you want to use with FLEX. You’ll be asked to
          sign requests for each connection. Don’t worry – FLEX only has
          permission to read the contents of your wallet, nothing else.{' '}
        </Text>
        <ConnectWallet buttonBg={'#1B21B9'} />

        <Text
          fontSize="13px"
          color="gray.400"
          fontWeight={'500'}
          textAlign="center"
        >
          Optional. If you prefer to add wallets later hit “Finish”.
        </Text>
        <WalletList user={userProfile} />
        <Box position="static" bottom="0" left="0" w="full" right="0">
          <Link as={ReachLink} to="/signup/confirm" color={'blue.400'}>
            <Button
              bg={'#19AB6A'}
              color={'white'}
              _hover={{
                bg: 'blue.500',
              }}
              w="full"
              mb="20px"
              type="submit"
            >
              Finish
            </Button>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
}
