import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Mainnet,
  DAppProvider,
  useEtherBalance,
  useEthers,
  Config,
  Rinkeby,
} from '@usedapp/core';

import { ReactKeycloakProvider } from '@react-keycloak/web';

import { authClient } from '@services/FlexConnectAPI';

import { QueryClient, QueryClientProvider } from 'react-query';

import theme from './theme';

import { Router, Link } from '@reach/router';

import { UserContextProvider } from './context/UserContext';

import Homepage from './pages/Homepage';
import FourFour from './pages/404';

import Signup from './screens/Signup';

import Account from './screens/Account';

import Profile from './screens/Profile';
import Logout from './screens/Logout';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const config = {
  readOnlyChainId: Rinkeby.chainId,
  readOnlyUrls: {
    [Rinkeby.chainId]:
      'https://rinkeby.infura.io/v3/7017a81cd75d42439e97b7ac76d4687b',
  },
  autoConnect: true,
};

function App() {
  return (
    <ReactKeycloakProvider authClient={authClient}>
      <ChakraProvider theme={theme}>
        <DAppProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <UserContextProvider>
              <Router>
                <Homepage path="/" />

                <Signup path="/signup/*" />
                <Account path="/account/*" />

                <Logout path="/logout" />
                <Profile path="/:username" />
                <FourFour path="/*" />
                <FourFour path="/404" />
                <FourFour path="/404.html" />
              </Router>
            </UserContextProvider>
          </QueryClientProvider>
        </DAppProvider>
      </ChakraProvider>
    </ReactKeycloakProvider>
  );
}

export default App;
