import { Text } from '@chakra-ui/react';

const HookFormErrorMessage = ({ errors, name }) => {
  if (errors[name] && errors[name].message) {
    return <Text color="red.500">{errors[name].message}</Text>;
  }
  return null;
};

export default HookFormErrorMessage;
