import {
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useDisclosure,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { ViewIcon, DeleteIcon } from '@chakra-ui/icons';
import { TokenBalanceList, TokenBalanceModal } from '../TokenBalance';

import moreIcon from '@icons/More.svg';

const MoreIcon = styled.div`
  background-image: url(${moreIcon});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 30px;
  height: 30px;
`;

const MenuActions = ({ account, onClickRemove, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure({
    id: `wallet:${account}`,
  });

  return (
    <>
      <Menu direction="ltr">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<MoreIcon />}
          variant="ghost"
        />
        <MenuList>
          <MenuItem icon={<ViewIcon />} onClick={onOpen}>
            Summary
          </MenuItem>
          {type != 'FLEXCONNECT WALLET' && (
            <MenuItem icon={<DeleteIcon />} onClick={onClickRemove}>
              Remove
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      {/* TODO: Fix useDisclosure doesn't work if not in same component */}
      <TokenBalanceModal isOpen={isOpen} onClose={onClose} account={account}>
        <TokenBalanceList account={account} />
      </TokenBalanceModal>
    </>
  );
};

export default MenuActions;
