import { Controller, useForm, useFieldArray } from 'react-hook-form';

import InputField from '@components/atoms/InputField';
import {
  Flex,
  Box,
  IconButton,
  Stack,
  StackDivider,
  Button,
} from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';

function InputProfileLinksFIeldArray({ control }) {
  const { register } = useForm();
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: 'links', // unique name for your Field Array
    }
  );

  return (
    <Box>
      <Stack divider={<StackDivider borderColor="gray.200" />}>
        {fields.map((field, index) => (
          <Flex key={index}>
            <Box flex="1">
              <InputField
                control={control}
                type="text"
                placeholder="Link title (e.g. My Twitter)"
                name={`links.${index}.title`}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                }}
              />

              <InputField
                control={control}
                type="text"
                placeholder="URL (e.g. www.twitter.com/frenchplace21)"
                name={`links.${index}.url`}
                rules={{
                  required: true,
                  minLength: {
                    value: 4,
                    message: 'Minimum length should be 4',
                  },
                  pattern: {
                    value:
                      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                    message: 'Please check the URL is valid',
                  },
                }}
              />
            </Box>
            <Box pl="10px">
              <IconButton
                mt="20px"
                color="#747681"
                onClick={() => remove(index)}
                variant="ghost"
                icon={<DeleteIcon />}
              />
            </Box>
          </Flex>
        ))}
      </Stack>
      <Button
        mt="20px"
        bg="#1B21B9"
        color="white"
        onClick={() => append('test')}
      >
        Add link
      </Button>
    </Box>
  );
}

export default InputProfileLinksFIeldArray;
