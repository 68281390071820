import React, { useEffect } from 'react';
import { Flex, Box, useDisclosure, Stack } from '@chakra-ui/react';

import MenuActions from './MenuActions';
import EditableInputField from '@components/atoms/EditableInputField';
import NFTPreview from './NFTPreview';
import WalletAddress from '@components/atoms/WalletAddress';
import ConnectorTag from './ConnectorTag';

const ConnectedWalletWidget = ({
  name,
  connectedWallet,
  account,
  type,
  uid,
  totalCount,
  images,
  updateWalletMutation,
  removeWalletMutation,
}) => {
  const isConnected = connectedWallet == account;

  const onClickRemove = () => {
    removeWalletMutation.mutate(uid);
  };

  return (
    <Box px={'20px'} w="100%">
      <Box
        w="100%"
        p="20px"
        rounded={'lg'}
        boxShadow={'lg'}
        overflow="hidden"
        display={'flex'}
        alignItems={{ base: 'flex-start', md: 'center' }}
        border="1px solid #EAEAEA;"
        flexDirection={{ base: 'column', md: 'row' }}
      >
        <Flex mb={{ base: '20px', sm: 0 }} width={{ base: '100%', sm: 'auto' }}>
          <Box flex={{ xs: 1, md: 0 }} width="100%">
            <EditableInputField
              onSubmit={async data => {
                await updateWalletMutation.mutate({ uid, data });
              }}
              name="name"
              defaultValues={{
                name,
              }}
            />
            <WalletAddress address={account} />
          </Box>
          <Box display={{ sm: 'none' }}>
            <MenuActions
              onClickRemove={onClickRemove}
              type={type}
              account={account}
            />
          </Box>
        </Flex>
        <Box flex="1" />
        <Stack
          direction={['column', 'row']}
          spacing={{ base: '20px', md: '10px' }}
        >
          <NFTPreview images={images} totalCount={totalCount} />
          <ConnectorTag connector={type} />
          <Box display={{ base: 'none', sm: 'block' }}>
            <MenuActions
              onClickRemove={onClickRemove}
              account={account}
              type={type}
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default ConnectedWalletWidget;
