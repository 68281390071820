import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

import flexBrand from '@icons/FLEX.svg';

const FLEXBrand = styled(Box)`
  background-image: url(${flexBrand});
  background-repeat: no-repeat;
  width: 80px;
  height: 22px;
`;

export default FLEXBrand;
