import styled from 'styled-components';
import { Box, Flex, Link, Text, HStack } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import { get } from 'lodash';

import { sdk } from '@/services/FlexConnectAPI';

import NavItem from './NavItem';

const FlexBrandHeader = ({ userProfile }) => {
  return (
    <Flex
      boxShadow={'lg'}
      maxW={'640px'}
      direction={{ base: 'column-reverse', md: 'row' }}
      width={'full'}
      py={'19px'}
      px={'20px'}
      // mb={10}
      bg="black"
      color="white"
      justifyContent={'space-between'}
      position={'relative'}
      mt="0"
    >
      <Flex
        direction={'row'}
        justifyContent={'space-between'}
        w="100%"
        h="22px"
        alignItems={'center'}
      >
        <HStack spacing="20px" flexDirection="row" alignItems="center">
          <NavItem
            iconIndex={0}
            to={`#signer`}
            onClick={e => {
              e.preventDefault();
              sdk.web3().signer.open();
            }}
          >
            Wallet
          </NavItem>
          <NavItem iconIndex={2} to={`/account/wallets`}>
            Linked
          </NavItem>
          <NavItem iconIndex={1} to={`/account/nfts`}>
            NFTs
          </NavItem>
          <NavItem iconIndex={3} to={`/account/settings`}>
            Settings
          </NavItem>
        </HStack>
        <Box flex="1" />
        <Box>
          <Link as={ReachLink} to={`/${userProfile?.username}`}>
            <Text fontSize={'18px'} textAlign="right">
              {userProfile?.username || '...'}
            </Text>
          </Link>
          <Text as="div" fontSize={'14px'} textAlign="right" color="gray.500">
            <Flex
              width="100%"
              display="flex"
              flexDirection={{ base: 'column', md: 'row' }}
              alignItems={{ base: 'flex-end', md: 'center' }}
            >
              <Link as={ReachLink} to={`/${userProfile?.username}`}>
                fle.xyz/{get(userProfile, 'username')}
              </Link>
              {/* <Box whiteSpace={'nowrap'}>
                {userProfile?.totalCount || 0} NFTs
              </Box>
              <Box px="10px" display={{ base: 'none', md: 'block' }}>
                •
              </Box>
              <Box
                whiteSpace={'nowrap'}
                display={{ base: 'none', md: 'block' }}
              >
                {userProfile?.wallets?.length} wallets linked
              </Box> */}
            </Flex>
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default FlexBrandHeader;
