import React, { useEffect } from 'react';
import { Box, Text, Image, Tag, Stack, HStack } from '@chakra-ui/react';

import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

const SkeletonWalletItem = ({}) => {
  return (
    <Box px={'20px'} w="100%">
      <Box
        w="100%"
        p="20px"
        rounded={'lg'}
        boxShadow={'lg'}
        overflow="hidden"
        display={'flex'}
        alignItems="center"
        border="1px solid #EAEAEA;"
      >
        <Box width={'100%'} maxWidth="160px">
          <Stack spacing="9px">
            <Skeleton height="20px" width="100%" />
            <Skeleton height="15px" width="80%" />
          </Stack>
        </Box>
        <Box flex="1" />
        <Stack direction={['column', 'row']} spacing="10px">
          <HStack spacing="-9px">
            <SkeletonCircle size="40px" />
            <SkeletonCircle size="40px" />
            <SkeletonCircle size="40px" />
          </HStack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SkeletonWalletItem;
